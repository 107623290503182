import { ChoiceType } from '@domain/rq/match/MatchingApplicationApplyRq';
import classnames from 'classnames/bind';
import styles from './addtionInfo.module.scss';
const cx = classnames.bind(styles);

interface Props {
  question: string;
  choices: ChoiceType[];
}

const MultiChoice = ({ question, choices }: Props) => {
  return (
    <div>
      <h4 className={cx('question')}>
        {question}
        <span className={cx('subQuestionText')}>(복수 선택)</span>
      </h4>
      <div className={cx('choiceArea')}>
        {choices?.map((choice) => (
          <div key={choice.name} className={cx('choice')}>
            <span className={cx('checkbox', { selected: choice.selectYn })} />
            <p className={cx('name', { selected: choice.selectYn })}>{choice.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiChoice;
