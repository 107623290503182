import Icon from '@components/common/assets';
import Text from '@components/position/jd/apply/modals/previewProfileModal/additionInfo/Text';

import SingleChoice from './SingleChoice';
import MultiChoice from './MultiChoice';
import Dropdown from './Dropdown';
import File from './File';
import { AddtionDto } from '@domain/vo/profile/ProfileDetailVO';
import classnames from 'classnames/bind';
import styles from './addtionInfo.module.scss';
const cx = classnames.bind(styles);

interface Props {
  additions?: AddtionDto[];
}

const AdditionInfo = ({ additions }: Props) => {
  if (!additions || additions.length === 0) return null;

  const formElements = additions.map((form) => {
    switch (form.type) {
      case 'TEXT':
        return <Text key={form.question} {...form} />;
      case 'SINGLE_CHOICE':
        return <SingleChoice key={form.question} {...form} />;
      case 'MULTI_CHOICE':
        return <MultiChoice key={form.question} {...form} />;
      case 'DROPDOWN':
        return <Dropdown key={form.question} {...form} />;
      case 'ADDITION_ATTACH_FILE':
        return <File key={form.question} {...form} />;
      default:
        return null;
    }
  });

  return (
    <section className={cx('addtionInfoWrapper')}>
      <h2 className={cx('title')}>
        <Icon name="fileAddLight" width={40} height={40} />
        추가 정보
      </h2>

      <div className={cx('formArea')}>{formElements}</div>
    </section>
  );
};

export default AdditionInfo;
