import React from 'react';
import { resultModalStateType } from '../phsRequest';
import MessageButton from '../../../common/button';
import { MatchingTalkApplyType } from '@domain/rs/match/message/MatchingMessageListRs';

import classnames from 'classnames/bind';
import styles from './resultSubmit.module.scss';
const cx = classnames.bind(styles);

interface ResultSubmitProps {
  submitType: MatchingTalkApplyType | null;
  setResultType: (submitType: MatchingTalkApplyType) => void;
  isUploaded?: boolean;
  setResultModalState: (resultModalState: resultModalStateType | null) => void; 
}

const submitTypeMap: Record<MatchingTalkApplyType, string> = {
  ACC: '역량검사',
  PACC: '개발자 역량검사',
  PST: '개발 구현 능력 검사',
};

const ResultSubmit = ({ submitType, setResultType, isUploaded, setResultModalState }: ResultSubmitProps) => {
  if (!submitType)
    return (
      <MessageButton className={cx('btnSubmit')} disabled>
        제출 기간 만료
      </MessageButton>
    );

  const handleSubmit = () => {
    setResultModalState('SELECT');
    setResultType(submitType);
  };

  return (
    <MessageButton className={cx('btnSubmit')} disabled={isUploaded} onClick={handleSubmit}>
      {isUploaded ? `${submitTypeMap[submitType]} 결과 제출 완료` : `${submitTypeMap[submitType]} 결과 제출`}
    </MessageButton>
  );
};

export default ResultSubmit;
