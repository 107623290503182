import { ChoiceType } from '@domain/rq/match/MatchingApplicationApplyRq';
import classnames from 'classnames/bind';
import styles from './addtionInfo.module.scss';
const cx = classnames.bind(styles);

interface Props {
  question: string;
  choices: ChoiceType[];
}

const Dropdown = ({ question, choices }: Props) => {
  return (
    <div>
      <h4 className={cx('question')}>{question}</h4>
      <p className={cx('answer')}>{choices.find((choice) => choice.selectYn)?.name}</p>
    </div>
  );
};

export default Dropdown;
