import Modal from '@components/common/modal';
import PreviewProfile from '@components/position/jd/apply/modals/previewProfileModal/previewProfile';
import AdditionInfo from './additionInfo';
import ProfileDetailVO from '@domain/vo/profile/ProfileDetailVO';

import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

interface Props {
  profileDetail: ProfileDetailVO;
  visible: boolean;
  onClose?: () => void;
}

const MatchingProfilePreviewModal = ({ profileDetail, visible, onClose }: Props) => {
  return (
    <Modal visible={visible}>
      <div className={cx('previewProfile')} onClick={onClose}>
        <div className={cx('layer')}>
          <div className={cx('previewProfileArea')} onClick={(e) => e.stopPropagation()}>
            {/* 프로필 정보 */}
            <PreviewProfile {...profileDetail} onClose={onClose} />

            {/* 추가 정보 */}
            <AdditionInfo additions={profileDetail.addition?.additionInfo} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MatchingProfilePreviewModal;
